<style scoped>
.el-menu {
  min-height: 100%;
  background-color: #fff;
  border-right: none;
}
.el-menu-item {
  color: #20242c;
}
.el-menu-item.is-active {
  color: #2d507e;
  background-color: #eff6ff;
}
.el-menu-item:hover {
  color: #2d507e !important;
}
</style>

<template>
  <el-menu :default-active="defaultPath" class="el-menu-vertical-demo" @select="menuItemClick">
    <el-menu-item v-for="(item, index) in menuList" :index="item.menuCode" :key="index">
      <i :class="item?.iconURL" style="margin-right: 10px"></i>
      <span>
        {{ item.menuTitle }}
      </span>
      <span style="font-weight: bold" v-if="item.completion">
        {{ item.completion !== 0 ? +item.completion + '%' : '' }}
      </span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'CmMenu',
  components: {},
  data() {
    return {
      menuList: [],
      keyValue: 0,
      currentPath: '',
      defaultPath: ''
    }
  },
  created() {},
  mounted() {
    if (this.menuList.length === 0) {
      this.getActiveUrlData()
    }
    this.$bus.$on('saveFormInfo', () => {
      this.getActiveUrlData()
    })
    if (this.$route.path === '/Detail/CommonModule') {
      const menuCode = sessionStorage.getItem('menuCode')
      this.defaultPath = menuCode
    } else {
      this.defaultPath = this.$route.path
    }
  },
  beforeDestroy() {
    this.$bus.$off('saveFormInfo')
  },
  methods: {
    getActiveUrlData() {
      let url = `/v1/webconsole/study/operation/menu/${this.$route.query.operationId}`
      this.$api.get(url).then((res) => {
        this.menuList = []

        if (res.data && res.data.data) {
          this.menuList = res.data.data.map((item) => {
            return {
              ...item,
              menuCode: `/Detail/${item.menuCode}`,
              code: item.menuCode
            }
          })
          this.$store.commit('setMenuList', this.menuList)
        }
      })
    },
    //index: 选中菜单项的 index, indexPath: 选中菜单项的 index path
    menuItemClick(index) {
      const query = window.location.hash.split('?')[1]
      const matchedElement = this.menuList.find((element) => index.includes(element.menuCode))

      if (matchedElement) {
        if (matchedElement.isUniversal === 1) {
          sessionStorage.setItem('menuCode', matchedElement.menuCode)
          this.$router.push('/Detail/CommonModule' + '?' + query)
          this.$emit('urlPath', index)
        } else {
          if (this.currentPath && this.currentPath === index) {
            this.$emit('urlPath', index)
          } else {
            this.$router.push(index + '?' + query)
            this.$emit('urlPath')
          }
        }
      }

      this.currentPath = index
      // if (index && index.includes('player')) {
      //   window.open(window.location.origin + '/report/#' + index + '?' + query)
      // } else {
      //   if (this.currentPath && this.currentPath === index) {
      //     this.$emit('urlPath', index)
      //   } else {
      //     this.$router.push(index + '?' + query)
      //     this.$emit('urlPath')
      //   }
      //   this.currentPath = index
      // }
    }
  }
}
</script>
