<style scoped lang="less">
.ml10 {
  margin-left: 10px;
}
.header-content {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  display: flex;
  justify-content: space-between;
  .main-left {
    display: flex;
    .info-header {
      height: 100%;
      display: flex;
      flex-direction: column;
      .info-header-item1 {
        height: 28px;
        line-height: 36px;
        .header-name {
          color: #fff;
          font-size: 18px;
        }
        .header-sex {
          color: #fff;
          font-size: 16px;
        }
        .header-label {
          color: #c7d0dc;
        }
      }
      .info-header-item2 {
        height: 32px;
        line-height: 32px;
        .header-label {
          color: #c7d0dc;
        }
      }
    }
  }
}
.info {
  display: inline;
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  .go-home {
    background: #7f95b4;
    border: #7f95b4;
    /deep/.el-icon-arrow-left {
      color: #fff;
      font-weight: bold;
    }
    /deep/.el-icon-arrow-left:hover {
      color: #2d507e;
    }
  }
  .timeNum::before {
    margin-right: 3px;
    content: '(';
  }
  .timeNum::after {
    margin-left: 3px;
    content: ')';
  }
  .local_time {
    float: right;
    margin-left: 10px;
    margin-right: 10px;
  }
  .user_name {
    float: right;
    margin-left: 10px;
  }
  @media screen and (max-width: 1180px) {
    .local_time {
      margin-right: 0;
    }
    .user_name {
      margin-left: 0;
    }
  }
}
</style>

<template>
  <div class="header-content">
    <div class="main-left">
      <div class="info" v-if="isGoHome">
        <el-button
          class="go-home"
          circle
          size="small"
          type="default"
          icon="el-icon-arrow-left"
          @click="goHome"
        ></el-button>
      </div>
      <div class="ml10 info-header">
        <div class="info-header-item1">
          <span class="header-name" v-if="currentPatient?.patientsName">
            {{ currentPatient?.patientsName }}&ensp;
          </span>
          <span class="header-sex" v-if="currentPatient?.patientsSex">
            {{ currentPatient?.patientsSex }}&ensp;
          </span>
        </div>
        <div class="info-header-item2">
          <span class="header-label" v-if="currentPatient?.sickArea">
            科室： {{ currentPatient?.sickArea }}&ensp;|&ensp;
          </span>
          <span class="header-label">
            手术状态: &ensp;
            <el-tag v-if="currentPatient?.status == '待排程'" class="tag" type="info" size="small">
              {{ currentPatient.status }}
            </el-tag>
            <el-tag
              v-if="currentPatient?.status == '未开始'"
              class="tag"
              type="warning"
              size="small"
            >
              {{ currentPatient.status }}
            </el-tag>
            <el-tag
              v-if="currentPatient?.status == '进行中'"
              class="tag"
              type="danger"
              size="small"
            >
              {{ currentPatient.status }}
            </el-tag>
            <el-tag
              v-if="currentPatient?.status == '已结束'"
              class="tag"
              type="success"
              size="small"
            >
              {{ currentPatient.status }}
            </el-tag>
            <el-tag
              v-if="currentPatient?.status == '已完成'"
              class="tag"
              type="success"
              size="small"
            >
              {{ currentPatient.status }}
            </el-tag>
            &ensp;|&ensp;
          </span>
          <span class="header-label">手术室: {{ currentPatient?.lab }}&ensp;</span>
          <div class="info ml10" v-if="currentPatient?.patientsID">
            <el-button
              round
              size="mini"
              icon="el-icon-picture"
              style="font-size: 14px"
              @click="goto_image"
            >
              介入影像
            </el-button>
          </div>
          <div class="info ml10">
            <el-button
              round
              size="mini"
              icon="el-icon-alarm-clock"
              style="font-size: 14px"
              @click="timeReckon"
            >
              计时选择
              <span class="timeNum" v-if="timeLength > 0">{{ timeLength }}</span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-right">
      <div class="info">
        <span class="local_time">
          {{ local_time | formateTime }}
        </span>
      </div>
      <div class="info">
        <span class="user_name">
          {{ this.user_name }}
        </span>
      </div>
    </div>

    <time-reckon-dialog @timeLength="getTimeLength" ref="timeReckonDialog" />
  </div>
</template>

<script>
var parseDate = function (datetime) {
  return datetime < 10 ? '0' + datetime : datetime
}
import { mapState } from 'vuex'
import TimeReckonDialog from './TimeReckonDialog.vue'
export default {
  name: 'CmHeader',
  components: {
    TimeReckonDialog
  },
  props: {},
  data() {
    return {
      date: new Date(),
      local_time: new Date(),
      user_name: '',
      timeLength: '',
      isGoHome: false
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  created() {
    this.user_name = localStorage.getItem('name')
    var that = this

    this.timer = setInterval(function () {
      that.local_time = new Date()
    }, 1000)
  },
  mounted() {
    this.isGoToHome()
  },
  filters: {
    formateTime: function (val) {
      var date = new Date(val)
      var year = date.getFullYear()
      var month = parseDate(date.getMonth() + 1)
      var day = parseDate(date.getDate())
      var hours = parseDate(date.getHours())
      var min = parseDate(date.getMinutes())
      var sec = parseDate(date.getSeconds())
      // var week = date.getDay();
      return year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec + ' '
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    calculateAgeFromDays(days) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()

      const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
      const birthYear = birthDate.getFullYear()

      let age = currentYear - birthYear

      // 检查生日是否已经过了，如果还没过，则减去一年
      if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
        age--
      }

      if (age < 1) {
        return days + '天'
      } else if (age < 2) {
        const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
        return '1年零' + remainingDays + '天'
      } else {
        return age + '岁'
      }
    },
    goHome() {
      this.$router.push('/Home')
    },
    isGoToHome() {
      if (this.$route.path.includes('/Detail')) {
        this.isGoHome = true
      }
    },
    goto_image() {
      const { href } = this.$router.resolve({
        path: `/dicomplayer?operationId=${this.currentPatient.uuid}&patientsID=${this.currentPatient.patientsID}`
      })
      window.open(href, '_blank')
    },
    // 计时弹窗
    timeReckon() {
      this.$refs.timeReckonDialog.Show()
    },
    getTimeLength(val) {
      this.timeLength = val
    }
  }
}
</script>
