<style scoped lang="less">
.full {
  padding: 0;
  margin: 0;
}
.home-content {
  width: 100vw;
  height: 100vh;
}
.home-header {
  background: #2d507e;
  cursor: default;
}
.home-menu {
  min-width: 180px;
  background: #ffffff;
  height: calc(100vh - 4rem);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.home-menu::-webkit-scrollbar {
  display: none;
}

.home-main {
  height: calc(100vh - 4rem);
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.home-footer {
  background: #cccccc;
  text-align: center;
  font-size: 12px;
}
</style>
<template>
  <el-container class="home-content">
    <el-header class="home-header" height="auto">
      <cm-header />
    </el-header>
    <el-container style="background: #eff0f7">
      <el-aside class="home-menu" width="auto">
        <cm-menu @urlPath="getPath" />
      </el-aside>
      <el-main class="full home-main">
        <router-view
          :info="patientInfo"
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :key="urlKey"
        />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import CmMenu from './CmMenu.vue'
import CmHeader from './CmHeader.vue'

export default {
  name: 'Home',
  components: {
    CmHeader,
    CmMenu
  },
  props: {},
  data() {
    return {
      patientInfo: null,
      urlKey: this.$route.path,
      loading: false
    }
  },
  watch: {
    '$route.params': {
      handler() {
        this.loading = true
      },
      deep: true
    }
  },
  beforeCreate() {
    var code = localStorage.getItem('userCode')
    if (code == undefined) {
      var url = `http://${location.host}`
      url += '/#/'
      alert('请先登录')
      window.open(url, '_self')
    }
  },
  created() {
    this.getCurrentPatient()
  },
  mounted() {
    let operationId = this.getQueryVariable('operationId')
    this.$store.dispatch('setCurrentPatientAct', operationId, false)
  },
  methods: {
    getPath(val) {
      if (val && val !== this.urlKey) {
        this.urlKey = val
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    getCurrentPatient() {
      let url = `/v1/webconsole/study/operation/get/${this.$route.query.operationId}`
      this.$api.get(url).then((res) => {
        this.patientInfo = res.data.data
      })
    },
    getQueryVariable(variable) {
      var query = window.location.hash.split('?')[1]
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    }
  }
}
</script>
