<style lang="less" scoped>
.top-item-row {
  margin: 10px 15px;
}
.content-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.item-row-time {
  width: 45%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  margin-right: 10px;
  span {
    margin: 0 10px;
  }
  .time-type {
    display: flex;
    align-items: center;
    .el-select {
      width: 150px;
      /deep/.el-input__inner {
        height: 28px;
        line-height: 28px;
      }
    }
    /deep/.el-input__inner {
      border: 1px solid #dcdfe6;
    }
    button {
      margin-left: 30px;
    }
  }
}
.item-navbar {
  width: calc(100% - 50px);
}
</style>

<template>
  <cm-dialog-border size="big" :zIndex="zIndex" title="计时选择" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="top-item-row">
      <div class="item-row-time item-navbar">
        <div class="time-type">
          <span>名称：</span>
          <el-select
            v-model="timeType"
            allow-create
            filterable
            clearable
            default-first-option
            placeholder="请选择计时"
            @blur="timeInputBlur"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input-number
            style="margin-left: 25px"
            placeholder="60"
            v-model="time"
            label="监听间隔"
            :min="0"
            :step="1"
            size="mini"
            step-strictly
            :disabled="time_visible"
          ></el-input-number>
          <el-tag>min</el-tag>
          <el-button type="primary" @click="createTime" size="small" icon="el-icon-timer">
            开始计时
          </el-button>
        </div>
      </div>
      <div class="content-row">
        <div v-for="(item, index) in list" :key="index" class="item-row-time">
          <div class="time-type">
            <el-tag effect="dark" type="warning">{{ item.title }}</el-tag>
            <el-tag>
              倒计时：
              <span style="margin: 0">{{ item.residueTime }}</span>
              s
            </el-tag>
            <el-tag>
              开始时间：
              <span style="margin: 0">{{ item.beginTime }}</span>
            </el-tag>
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="deleteTime(item, index)"
              circle
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </cm-dialog-border>
</template>

<script>
import CmDialogBorder from './CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { toDateDetail } from '../utils/index'

export default {
  name: 'TimeReckonDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      //计时时长
      time: 1,
      time_visible: false,
      istime_Start: false,
      options: [
        {
          value: '肝素计时',
          label: '肝素计时'
        }
      ],
      list: [],
      timeType: '',
      timeList: []
    }
  },
  watch: {
    list: {
      handler() {
        this.$emit('timeLength', this.list.length)
      },
      immediate: true
    }
  },
  mounted() {},
  computed: {},
  methods: {
    toDateDetail,
    Show() {
      this.isShow = true
    },
    timeInputBlur(e) {
      this.timeType = e.target.value
    },
    createTime() {
      if (!this.timeType) return this.$message.warning('请选择计时类型！')
      const element = this.list.some((item) => item.title === this.timeType)
      if (element) {
        return this.$message.warning('已存在该计时类型！')
      }
      const item = {
        title: this.timeType,
        residueTime: this.time * 60,
        beginTime: this.toDateDetail(new Date())
      }
      this.list.push(item)
      this.gettime(item)
    },
    open_save() {
      this.isShow = false
    },
    Close() {
      this.isShow = false
    },
    deleteTime(element) {
      this.list = this.list.filter((item) => item.title !== element.title)
      this.clear(element)
    },
    gettime(item) {
      this.$message.warning(item.title + '已开始!')
      let maxtime = this.time * 60
      let id = 0
      let time1 = setInterval(() => {
        if (id == 60) (this.time -= 1), (id = 0)
        if (maxtime > 1) {
          if (maxtime == 5 * 60) {
            this.$message.warning(item.title + '还剩五分钟！')
          }
          ++id
          --maxtime
          this.list.map((element) => {
            if (element.title === item.title) {
              element.residueTime = maxtime
            }
          })
        } else {
          clearInterval(time1)
          // 清除掉计时器对应的计时内容
          this.timeList.map((element, index, arr) => {
            if (element.title === item.title) {
              arr.splice(index, 1)
              this.list.splice(index, 1)
            }
          })
          let videotimer = setInterval(() => {
            let utterThis = new window.SpeechSynthesisUtterance(item.title + '结束')
            window.speechSynthesis.cancel()
            window.speechSynthesis.speak(utterThis)
          }, 2500)
          this.$confirm('注意!' + item.title + '已结束!!', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            showClose: 'true'
          })
            .then(() => {
              clearInterval(videotimer)
            })
            .catch(() => {
              clearInterval(videotimer)
            })
        }
      }, 1000)
      const params = { name: time1, title: item.title }
      this.timeList.push(params)
    },
    // 清除计时器
    clear(item) {
      this.$message.warning(item.title + '已关闭!')
      this.timeList.map((element, index, arr) => {
        if (element.title === item.title) {
          clearInterval(element.name)
          arr.splice(index, 1)
        }
      })
    }
  }
}
</script>
